import React, { FunctionComponent } from "react";
import { Button } from "./common/Buttons";
import { TextField, Typography, makeStyles, Theme } from "@material-ui/core";

interface Props {
  name: string;
  email: string;
  setName: (value: string) => void;
  setEmail: (value: string) => void;
  setCalc: (value: boolean) => void;
}

const validateEmail = (email: string) => {
  if (
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      email
    )
  ) {
    return true;
  } else return false;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "1em",
    padding: "1em",
  },
  btn: {
    margin: "0 .5em",
  },
  input: {
    minWidth: 300,
  },
  marginBottom: {
    marginBottom: theme.spacing(4),
  },
}));

export const EmailForm: FunctionComponent<Props> = ({
  name,
  setName,
  email,
  setEmail,
  setCalc,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography variant="h4" className={classes.marginBottom}>
        To acess our BoxLock for Business price calculator, please enter your
        full name and your work email address.
      </Typography>
      <TextField
        autoFocus
        id="name"
        label="Name"
        className={classes.input}
        onChange={(e) => setName(e.target.value)}
        variant="filled"
      />
      <TextField
        id="email"
        label="Work Email Address"
        type="email"
        className={classes.input}
        onChange={(e) => setEmail(e.target.value)}
        variant="filled"
      />
      <Button
        variant="contained"
        label="Enter"
        onClick={() => setCalc(true)}
        color="primary"
        disabled={!name || !validateEmail(email)}
        className={classes.btn}
      />
    </div>
  );
};
