import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  FunctionComponent,
} from "react";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import {
  Select,
  Divider,
  MenuItem,
  Typography,
  Tooltip,
} from "@material-ui/core";
import { OrderForm } from "./OrderForm";
import { useLocalStorage } from "../utils/hooks";
import { Modal } from "./common/Modal";
import { ConfirmDialog } from "./ConfirmDialog";
import { API } from "aws-amplify";
import { getPrices } from "../graphql/queries";
import { createDeal } from "../graphql/mutations";
import CircularProgress from "@material-ui/core/CircularProgress";
import RefreshIcon from "@material-ui/icons/Refresh";
import { useHistory } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import Checkbox from "@material-ui/core/Checkbox";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import InfoIcon from "@material-ui/icons/Info";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: 0,
    [theme.breakpoints.down("sm")]: {
      width: 60,
      fontSize: ".6rem",
    },
    [theme.breakpoints.up("md")]: {
      width: 75,
    },
  },
  checkbox: {
    marginLeft: theme.spacing(1),
  },
  selected: {
    background: `${theme.palette.primary.main}!important`,
    color: "black!important",
  },
  select: {
    maxWidth: 75,
  },
  toggleContainer: {
    display: "flex",
    margin: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-between",
    },
  },
  selectContainer: {
    display: "flex",
    alignItems: "center",
  },
  totals: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  totalNote: {
    textAlign: "right",
  },
  label: {
    [theme.breakpoints.down("sm")]: {
      minWidth: 130,
      fontSize: "1em",
    },
    [theme.breakpoints.up("md")]: {
      minWidth: 175,
    },
  },
  icon: {
    position: "relative",
    top: "-.06em",
    height: "0.6rem",
    width: "auto",
  },
  divider: {
    margin: 0,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
    background: "black!important",
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(4),
      marginLeft: theme.spacing(4),
    },
  },
  price: {
    fontFamily: "Copenhagen",
    margin: 0,
  },
  secondary: {
    color: "#505050",
  },

  buttons: {
    display: "flex",
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    margin: "5em",
  },
  refreshContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "5em",
  },
  calcContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  info: {
    textAlign: "center",
  },
  infoIcon: {
    height: 20,
    color: "#808080!important",
  },
  infoIconContainer: {
    display: "flex",
    alignItems: "center",
  },
  listBox: {
    maxHeight: "60vh",
    overflowY: "auto",
    margin: 0,
    background: theme.palette.common.white,
    border: `1px solid ${theme.palette.common.black}`,
  },
}));

const formatNumber = (number: number): string => {
  return String(Math.round(number)).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

interface Props {
  name: string;
  email: string;
}

interface PriceObject {
  flat_amount: number;
  unit_amount: number;
  up_to: number | null;
}

interface Prices {
  wifi: number;
  lte: number;
  annual: [PriceObject];
  monthly: [PriceObject];
  annualApi: [PriceObject];
  monthlyApi: [PriceObject];
}

const initialPrice = {
  flat_amount: 0,
  unit_amount: 0,
  up_to: null,
};

const getOptions = () => {
  const array = [];

  for (var i = 3; i <= 50; i++) {
    array.push(i);
  }
  return array.map((num) => (
    <MenuItem key={num} value={num}>
      {num}
    </MenuItem>
  ));
};

const BillingCalculator: FunctionComponent<Props> = ({ name, email }) => {
  const [locks, setLocks] = useLocalStorage("locks", 3);
  const [wifi, setWifi] = useLocalStorage("wifi", true);
  const [annual, setAnnual] = useLocalStorage("annual", true);
  const [api, setApi] = useLocalStorage("api", false);
  const [modal, setModal] = useState<boolean>(false);
  const [type, setType] = useState<string>("");
  const [prices, setPrices] = useState<Prices>({
    wifi: 0,
    lte: 0,
    annual: [initialPrice],
    monthly: [initialPrice],
    annualApi: [initialPrice],
    monthlyApi: [initialPrice],
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [sentInvoice, setSentInvoice] = useState<boolean>(false);

  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();

  const vertical = useMediaQuery(theme.breakpoints.up("md"));

  const handleWifiChange = () => {
    setWifi((prev: boolean) => !prev);
  };

  const handleAnnualChange = () => {
    setAnnual((prev: boolean) => !prev);
  };

  const fetchPrices = useCallback(async () => {
    setLoading(true);
    try {
      const prices: any = await API.graphql({
        query: getPrices,
      });
      setLoading(false);
      setPrices(prices.data.getPrices);
    } catch {
      setLoading(false);
      setType("refresh");
    }
  }, []);

  const onClose = useCallback(async () => {
    const input = {
      name,
      email,
      locks,
      wifi,
      annual,
      api,
      sentInvoice,
    };
    const deal: any = await API.graphql({
      query: createDeal,
      variables: { input },
    });
    return deal;
  }, [annual, api, name, email, locks, wifi, sentInvoice]);

  useEffect(() => {
    fetchPrices();
  }, [fetchPrices]);

  useEffect(() => {
    window.addEventListener("beforeunload", onClose);
    return () => {
      window.removeEventListener("beforeunload", onClose);
    };
  }, [onClose]);

  const calculateLockCost = useCallback(
    (locks: number, wifi: boolean): number => {
      return locks * (wifi ? prices.wifi : prices.lte);
    },
    [prices]
  );

  const calculateSoftwareCost = useCallback(
    (locks: number, annual: boolean, api: boolean): number => {
      const key = annual
        ? api
          ? "annualApi"
          : "annual"
        : api
        ? "monthlyApi"
        : "monthly";
      const price: PriceObject =
        prices[key].find((p) => {
          return p.up_to === locks || p.up_to === null;
        }) || initialPrice;
      return (
        (locks * (price.unit_amount * 0.01) + price.flat_amount * 0.01) /
        (annual ? 12 : 1)
      );
    },
    [prices]
  );

  const calculateGrandTotal = useCallback(
    (locks: number, wifi: boolean, annual: boolean, api: boolean): number => {
      return Math.round(
        calculateLockCost(locks, wifi) +
          calculateSoftwareCost(locks, annual, api) * 12
      );
    },
    [calculateLockCost, calculateSoftwareCost]
  );

  const lockTotal = useMemo(
    () => formatNumber(calculateLockCost(locks, wifi)),
    [locks, wifi, calculateLockCost]
  );
  const softwareTotal = useMemo(
    () => formatNumber(calculateSoftwareCost(locks, annual, api)),
    [locks, annual, calculateSoftwareCost, api]
  );
  const grandTotal = useMemo(
    () => formatNumber(calculateGrandTotal(locks, wifi, annual, api)),
    [locks, wifi, annual, calculateGrandTotal, api]
  );

  const openModal = () => setModal(true);

  const closeModal = () => setModal(false);

  const onResponse = (type: string) => {
    setType(type);
    openModal();
    if (type === "success") setSentInvoice(true);
  };

  const refresh = () => history.go(0);

  const options = useMemo(() => getOptions(), []);

  return (
    <div>
      <Modal isShowing={modal} hide={closeModal}>
        <ConfirmDialog type={type} closeModal={closeModal} />
      </Modal>
      {loading ? (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      ) : type === "refresh" ? (
        <div className={classes.refreshContainer}>
          <h4>
            An error occured fetching prices. Please try refreshing the page.
          </h4>
          <IconButton onClick={refresh}>
            <RefreshIcon color="primary" fontSize="large" />
          </IconButton>
        </div>
      ) : (
        <>
          <Typography className={classes.info}>
            The calculator below will calculate the price for BoxLock hardware,
            as well the the software fees based on the total number of locks
            your Business needs. Lock hardware cost is billed up front, while
            software can be billed monthly or annually for a discount.
          </Typography>
          <div className={classes.calcContainer}>
            <div>
              <div className={classes.toggleContainer}>
                <h3 className={classes.label}>Number of Locks</h3>
                <Select
                  labelId="lock-count-label"
                  id="lock-count-select"
                  value={locks}
                  onChange={(e) => setLocks(Number(e.target.value))}
                  className={classes.select}
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    classes: { paper: classes.listBox },
                  }}
                >
                  {options}
                </Select>
              </div>
              <div className={classes.toggleContainer}>
                <h3 className={classes.label}>Lock Type</h3>
                <ToggleButtonGroup
                  value={wifi}
                  exclusive
                  onChange={handleWifiChange}
                  aria-label="text alignment"
                >
                  <ToggleButton
                    value={true}
                    aria-label="left aligned"
                    classes={{ selected: classes.selected, root: classes.root }}
                  >
                    Wifi
                  </ToggleButton>
                  <ToggleButton
                    value={false}
                    aria-label="centered"
                    classes={{ selected: classes.selected, root: classes.root }}
                  >
                    LTE-M
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
              <div className={classes.toggleContainer}>
                <h3 className={classes.label}>Billing Plan</h3>
                <ToggleButtonGroup
                  value={annual}
                  exclusive
                  onChange={handleAnnualChange}
                  aria-label="text alignment"
                >
                  <ToggleButton
                    value={true}
                    aria-label="left aligned"
                    classes={{ selected: classes.selected, root: classes.root }}
                  >
                    Annual
                  </ToggleButton>
                  <ToggleButton
                    value={false}
                    aria-label="centered"
                    classes={{ selected: classes.selected, root: classes.root }}
                  >
                    Monthly
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
              <div className={classes.toggleContainer}>
                <div className={classes.infoIconContainer}>
                  <h3>API Access</h3>
                  <Tooltip
                    title="Secure APIs that integrate into administrative, materials management, and enterprise solutions"
                    placement="top"
                  >
                    <InfoIcon className={classes.infoIcon} />
                  </Tooltip>
                </div>
                <Checkbox
                  checked={api}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setApi(e.target.checked)
                  }
                  name="api"
                  color="primary"
                />
              </div>
            </div>
            <Divider
              variant={vertical ? undefined : "middle"}
              orientation={vertical ? "vertical" : "horizontal"}
              className={classes.divider}
              flexItem
            />
            <div>
              <div className={classes.totals}>
                <Typography variant="h2">Total</Typography>
                <div className={classes.totalNote}>
                  <Typography variant="h2" className={classes.price}>
                    ${grandTotal}
                  </Typography>
                  <Typography variant="body1">for first year</Typography>
                </div>
              </div>
              <div className={classes.secondary}>
                <div className={classes.totals}>
                  <Typography variant="h4">Software</Typography>
                  <div className={classes.totalNote}>
                    <Typography variant="h4" className={classes.price}>
                      ${softwareTotal}
                    </Typography>
                    <Typography variant="body1">
                      per month, billed {annual ? "annually" : "monthly"}
                    </Typography>
                  </div>
                </div>
                <div className={classes.totals}>
                  <Typography variant="h4">Locks</Typography>
                  <div className={classes.totalNote}>
                    <Typography variant="h4" className={classes.price}>
                      ${lockTotal}
                    </Typography>
                    <Typography variant="body1">paid up front</Typography>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.buttons}>
            <OrderForm
              locks={locks}
              wifi={wifi}
              annual={annual}
              api={api}
              onResponse={onResponse}
              initialName={name}
              initialEmail={email}
              setSentInvoice={setSentInvoice}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default BillingCalculator;
