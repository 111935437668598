import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button as MUIButton } from "@material-ui/core";
import { ChevronIcon } from "../../assets/img/icons";

const useStyles = makeStyles({
  icon: {
    position: "relative",
    top: "-.03em",
    height: "0.6rem",
    width: "auto",
    marginRight: ".3em",
  },
});

export const Button = (props: any) => {
  const { hideIcon = false, label, ...rest } = props;
  const classes = useStyles();

  return (
    <MUIButton {...rest}>
      <div>{!hideIcon && <ChevronIcon className={classes.icon} />}</div>
      {label}
    </MUIButton>
  );
};
