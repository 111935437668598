import React, { FunctionComponent, useEffect, useState } from "react";
import { Button } from "./common/Buttons";
import { TextField, Dialog, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { API } from "aws-amplify";
import { createInvoice } from "../graphql/mutations";
import { useQuery } from "../utils/hooks";
import CircularProgress from "@material-ui/core/CircularProgress";

interface Props {
  locks: number;
  wifi: boolean;
  annual: boolean;
  api: boolean;
  onResponse: (type: string) => void;
  initialName: string;
  initialEmail: string;
  setSentInvoice: (val: boolean) => void;
}

const useStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "center",
    margin: "1em",
    marginLeft: 0,
    width: "100%",
  },
  modal: {
    padding: "2em",
  },
  buttons: {
    display: "flex",
    marginTop: "2em",
    width: "100%",
    justifyContent: "center",
  },
  btn: {
    margin: "0 .5em",
  },
  invoice: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  labelContainer: {
    display: "flex",
    alignItems: "center",
  },
  label: {
    marginRight: "1em",
  },
  stateZipContainer: {
    display: "flex",
  },
  state: {
    width: "calc(50% - .25em)",
    marginRight: ".5em",
  },
  zip: {
    width: "calc(50% - .25em)",
    marginLeft: ".5em",
  },
});

const defaultAddress = {
  line1: "",
  line2: "",
  city: "",
  state: "",
  postal_code: "",
};

interface Address {
  line1: string;
  line2: string;
  city: string;
  state: string;
  postal_code: string;
}

export const OrderForm: FunctionComponent<Props> = ({
  locks,
  wifi,
  annual,
  api,
  onResponse,
  initialName,
  initialEmail,
  setSentInvoice,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [name, setName] = useState<string>(initialName);
  const [email, setEmail] = useState<string>(initialEmail);
  const [company, setCompany] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [address, setAddress] = useState<Address>(defaultAddress);
  const [loading, setLoading] = useState<boolean>(false);
  const classes = useStyles();

  const query = useQuery();

  useEffect(() => {
    if (query.get("name") || query.get("email")) {
      setName(query.get("name") || "");
      setEmail(query.get("email") || "");
      setOpen(true);
    }
  }, [query]);

  const handleSend = async () => {
    setLoading(true);
    const input = {
      name,
      email,
      locks,
      wifi,
      annual,
      company,
      address,
      phone,
      api,
    };
    const sentEmail: any = await API.graphql({
      query: createInvoice,
      variables: { input },
    });
    const type = sentEmail.data?.createInvoice?.success ? "success" : "error";
    setLoading(false);
    setSentInvoice(true);
    handleClose();
    onResponse(type);
  };

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAddress((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.container}>
      <Button
        label="Generate Quote"
        variant="contained"
        color="primary"
        onClick={handleClickOpen}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <div className={classes.modal}>
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              <Typography variant="h2">Company Information</Typography>
              <Typography variant="body2">
                Please complete the billing information for your business below
                so we can prepare your quote. This does not obligate you to
                purchase.
              </Typography>
              <div className={classes.invoice}>
                <div className={classes.labelContainer}>
                  <h4 className={classes.label}>Locks</h4>
                  <p>{locks}</p>
                </div>
                <div className={classes.labelContainer}>
                  <h4 className={classes.label}>Lock Type</h4>
                  <p>{wifi ? "WiFi" : "LTE-M"}</p>
                </div>
                <div className={classes.labelContainer}>
                  <h4 className={classes.label}>Billing</h4>
                  <p>{annual ? "Annual" : "Monthly"}</p>
                </div>
                <div className={classes.labelContainer}>
                  <h4 className={classes.label}>API Access</h4>
                  <p>{api ? "Yes" : "No"}</p>
                </div>
              </div>
              <TextField
                value={name}
                id="name"
                label="Name"
                fullWidth
                onChange={(e) => setName(e.target.value)}
                variant="filled"
                required
              />
              <TextField
                id="email"
                value={email}
                label="Email Address"
                type="email"
                fullWidth
                onChange={(e) => setEmail(e.target.value)}
                variant="filled"
                required
              />
              <TextField
                autoFocus
                value={company}
                id="company"
                label="Company"
                fullWidth
                onChange={(e) => setCompany(e.target.value)}
                variant="filled"
              />
              <TextField
                value={phone}
                id="phone"
                label="Phone"
                fullWidth
                onChange={(e) => setPhone(e.target.value)}
                variant="filled"
                required
              />
              <TextField
                value={address.line1}
                id="line1"
                label="Address Line 1"
                fullWidth
                onChange={handleAddressChange}
                variant="filled"
              />
              <TextField
                value={address.line2}
                id="line2"
                label="Address Line 2"
                fullWidth
                onChange={handleAddressChange}
                variant="filled"
              />
              <TextField
                value={address.city}
                id="city"
                label="City"
                fullWidth
                onChange={handleAddressChange}
                variant="filled"
              />
              <div className={classes.stateZipContainer}>
                <TextField
                  value={address.state}
                  id="state"
                  label="State"
                  onChange={handleAddressChange}
                  variant="filled"
                  className={classes.state}
                />
                <TextField
                  value={address.postal_code}
                  id="postal_code"
                  label="Postal Code"
                  onChange={handleAddressChange}
                  variant="filled"
                  className={classes.zip}
                />
              </div>

              <div className={classes.buttons}>
                <Button
                  variant="contained"
                  label="Email Quote"
                  onClick={handleSend}
                  color="primary"
                  disabled={!name || !email || !phone}
                  className={classes.btn}
                />
                <Button
                  variant="contained"
                  label="Cancel"
                  onClick={handleClose}
                  color="secondary"
                  className={classes.btn}
                />
              </div>
            </>
          )}
        </div>
      </Dialog>
    </div>
  );
};
