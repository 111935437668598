import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Logo from "../assets/img/logo.png";
import SmallLogo from "../assets/img/logosmall.png";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "95vh",
  },
  layout: {
    background: "#fff",
    width: "90%",
    height: "100%",
    padding: "2%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  header: { background: "#fff!important" },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  footer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "90%",
  },
  legalContainer: {
    display: "flex",
    justifySelf: "flex-start",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
    },
    marginBottom: theme.spacing(3),
  },
  copywrite: { fontFamily: "Copenhagen", textTransform: "uppercase" },
  legalLinks: {
    marginLeft: ".5rem",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginBottom: "1rem",
    },
  },
  link: {
    margin: "1em",
    fontFamily: "Arial,Helvetica,sans-serif",
    [theme.breakpoints.down("sm")]: {
      margin: ".5em",
    },
  },
  imgContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  title: {
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5em",
    },
  },
  img: {
    width: "auto",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "16px",
    },
    [theme.breakpoints.up("md")]: {
      maxHeight: "25px",
    },
  },
  logo: {
    width: "auto",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "30px",
    },
    [theme.breakpoints.up("md")]: {
      maxHeight: "50px",
    },
  },
}));

const Layout = (props: any) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <AppBar position="static" className={classes.header}>
        <Toolbar>
          <img src={Logo} className={classes.img} alt="logo" />
        </Toolbar>
      </AppBar>
      <div className={classes.layout}>
        <h1 className={classes.title}>Price Calculator</h1>
        {props.children}
      </div>
      <div className={classes.footer}>
        <div className={classes.legalContainer}>
          <h4 className={classes.copywrite}>
            © {new Date().getFullYear()} BOXLOCK, INC.
          </h4>
          <span className={classes.legalLinks}>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.getboxlock.com/privacy-policy"
              className={classes.link}
            >
              Privacy Policy
            </a>
            |
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.getboxlock.com/terms-of-service"
              className={classes.link}
            >
              Terms &amp; Conditions
            </a>
            |
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.getboxlock.com/signinwithgoogle"
              className={classes.link}
            >
              Sign-In With Google Security Policy
            </a>
          </span>
        </div>
        <div className={classes.imgContainer}>
          <img src={SmallLogo} className={classes.img} alt="logo" />
        </div>
      </div>
    </div>
  );
};

export default Layout;
