import React, { useEffect, useState } from "react";
import "./App.css";
import BillingCalculator from "./BillingCalculator";
import BoxLockTheme from "../theme";
import { ThemeProvider } from "@material-ui/core/styles";
import Layout from "./Layout";
import { Amplify } from "@aws-amplify/core";
import awsExports from "../aws-exports";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { EmailForm } from "./EmailForm";
import { useLocalStorage } from "../utils/hooks";

Amplify.configure(awsExports);

function App() {
  const [name, setName] = useLocalStorage("name", "");
  const [email, setEmail] = useLocalStorage("email", "");
  const [showCalc, setCalc] = useState<boolean>(false);

  // only run on mount
  useEffect(() => {
    if (name && email) setCalc(true);
  }, []); // eslint-disable-line

  return (
    <ThemeProvider theme={BoxLockTheme}>
      <Router>
        <Layout>
          <Switch>
            <Route path="/">
              {showCalc ? (
                <BillingCalculator name={name} email={email} />
              ) : (
                <EmailForm
                  name={name}
                  setName={setName}
                  email={email}
                  setEmail={setEmail}
                  setCalc={setCalc}
                />
              )}
            </Route>
          </Switch>
        </Layout>
      </Router>
    </ThemeProvider>
  );
}

export default App;
