/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createQuote = /* GraphQL */ `
  mutation CreateQuote($input: CreateQuoteInput!) {
    createQuote(input: $input) {
      success
      error
      message
    }
  }
`;
export const createInvoice = /* GraphQL */ `
  mutation CreateInvoice($input: CreateInvoiceInput!) {
    createInvoice(input: $input) {
      success
      error
      message
    }
  }
`;
export const createDeal = /* GraphQL */ `
  mutation CreateDeal($input: CreateDealInput!) {
    createDeal(input: $input) {
      success
      error
      message
    }
  }
`;
