import { createMuiTheme } from "@material-ui/core/styles";

const BoxLockTheme = createMuiTheme({
  palette: {
    secondary: {
      light: "#ffe98f",
      main: "#fec803",
      dark: "#ffc700",
      contrastText: "#000",
    },
    success: {
      main: "#37b49d",
    },
    error: {
      main: "#error",
    },
    primary: {
      light: "rgba(254, 200, 3, 1)",
      main: "#fec803",
      dark: "#e6b200",
      contrastText: "#000",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
    },
  },
  typography: {
    fontFamily: "Apercu, Helvetica, Arial, sans-serif",
    h1: {
      fontFamily: "Copenhagen",
      textTransform: "uppercase",
    },
    h2: {
      fontFamily: "Copenhagen",
      fontSize: "1.5rem",
      fontWeight: "bold",
      textTransform: "uppercase",
    },
    h3: {
      fontFamily: "Copenhagen",
      textTransform: "uppercase",
    },
    h4: {
      fontFamily: "Apercu",
      fontSize: "1.225rem",
      fontWeight: 600,
    },
    h5: {
      fontFamily: "Apercu",
    },
    h6: {
      fontFamily: "Apercu",
      fontSize: "14px",
      fontWeight: 600,
    },
    subtitle1: {
      fontFamily: "Apercu",
      color: "#000!important",
      fontWeight: "bold",
      margin: "1em 0",
    },
    button: {
      fontFamily: "Copenhagen",
      textTransform: "uppercase",
    },
  },
  props: {
    MuiSvgIcon: {
      htmlColor: "#1d1d1dß",
    },
  },
  overrides: {
    MuiButton: {
      textPrimary: { color: "#545454" },
      containedSecondary: {
        background: "#dedede!important",
        "&:hover": {
          background: "#eee!important",
        },
      },
      contained: {
        boxShadow: "none",
        "&:hover": {
          boxShadow: "none!important",
          background: "#ffd845!important",
        },
        padding: ".5rem 1em .25rem 1em !important",
      },
      containedSizeSmall: {
        lineHeight: "1em",
        padding: ".75rem 1em .5rem 1em !important",
      },
      root: { borderRadius: "none" },
    },
    MuiTableCell: {
      head: {
        textTransform: "uppercase",
        fontWeight: "bold",
      },
    },

    MuiCssBaseline: {
      "@global": {
        ".RaLayout-content-4": {
          padding: "3em !important",
          background: "#fff",
        },
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 0,
      },
      elevation1: {
        boxShadow: "none",
      },
      elevation4: {
        boxShadow: "none",
      },
    },
    MuiSelect: {
      root: {
        background: "white",
        borderRadius: "0!important",
        fontFamily: "Apercu",
      },
      select: {
        "&:focus": {
          background: "white!important",
        },
      },
    },
    MuiMenuItem: {
      root: { fontFamily: "Apercu" },
    },
    MuiFilledInput: {
      underline: {
        "&:before": {
          borderBottom: "none",
        },
      },
      root: {
        background: "white!important",
        border: "1px solid black",
        borderRadius: "0!important",
        marginBottom: "1em",
        fontFamily: "Apercu",
      },
    },
    MuiInputLabel: {
      root: { fontFamily: "Apercu" },
    },
    MuiChip: {
      root: {
        maxWidth: 100,
        maxHeight: 20,
      },
      label: {
        fontSize: 10,
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: "none",
        // fontFamily: "Copenhagen",
        textTransform: "uppercase",
        fontSize: ".75rem",
        padding: ".5rem 1em .25rem 1em !important",
      },
    },
    MuiIconButton: {
      root: {
        borderRadius: "none",
      },
    },
    MuiListItem: {
      root: {
        borderBottom: "1px solid #E7E7E7",
        "&:last-child": {
          borderBottom: "none",
        },
      },
    },
  },
});

BoxLockTheme.typography.body1 = {
  [BoxLockTheme.breakpoints.down("sm")]: {
    fontSize: "12px",
  },
};

export default BoxLockTheme;
