import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import DoneIcon from "@material-ui/icons/Done";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import { Button } from "./common/Buttons";

interface Props {
  closeModal: () => void;
  type: string;
}

export const ConfirmDialog = ({ closeModal, type }: Props) => {
  const useStyles = makeStyles({
    successHeader: {
      background: type === "success" ? "#37b49d" : "#ee3758",
      color: "#fff",
      width: "100%",
      height: "6rem",
      display: "flex",
      alignItems: "center",
    },
    successButton: {
      background: type === "success" ? "#37b49d" : "#ee3758",
      color: "#fff",
    },
    btns: {
      display: "flex",
      justifyContent: "center",
    },
    link: {
      textDecoration: "underline",
    },
  });

  const classes = useStyles();

  return (
    <>
      <div className={classes.successHeader}>
        <div
          style={{
            marginLeft: "3rem",
            display: "flex",
            alignItems: "center",
            alignContent: "center",
            height: "100%",
            fontSize: "3rem",
          }}
        >
          {type === "success" ? (
            <DoneIcon fontSize="large" />
          ) : (
            <ErrorOutline fontSize="large" />
          )}
          <h1
            style={{
              display: "inline",
              marginLeft: "1rem",
              fontFamily: "Apercu",
              fontSize: "2.2rem",
              paddingTop: "3px",
            }}
          >
            {type === "success" ? "Success" : "Error"}
          </h1>
        </div>
      </div>
      <div style={{ padding: "1.5em" }}>
        {type === "success" && (
          <>
            <h4 style={{ fontWeight: 500, margin: 0 }}>
              Your quote was created and sent to the email provided. Please
              contact{" "}
              <a href="mailto:sales@getboxlock.com" className={classes.link}>
                sales@getboxlock.com
              </a>{" "}
              with any questions.
            </h4>
          </>
        )}
        <div className={classes.btns}>
          <Button
            variant="contained"
            label="Got it"
            className={classes.successButton}
            style={{ marginTop: "2.5rem" }}
            onClick={closeModal}
          />
        </div>
      </div>
    </>
  );
};
