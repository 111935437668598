/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPrices = /* GraphQL */ `
  query GetPrices($input: String) {
    getPrices(input: $input) {
      monthly {
        flat_amount
        flat_amount_decimal
        unit_amount
        unit_amount_decimal
        up_to
      }
      annual {
        flat_amount
        flat_amount_decimal
        unit_amount
        unit_amount_decimal
        up_to
      }
      monthlyApi {
        flat_amount
        flat_amount_decimal
        unit_amount
        unit_amount_decimal
        up_to
      }
      annualApi {
        flat_amount
        flat_amount_decimal
        unit_amount
        unit_amount_decimal
        up_to
      }
      wifi
      lte
    }
  }
`;
export const test = /* GraphQL */ `
  query Test($input: String) {
    test(input: $input) {
      success
      error
      message
    }
  }
`;
