import React, { useEffect, useRef, useCallback } from "react";
import ReactDOM from "react-dom";
import { makeStyles } from "@material-ui/core/styles";
import { animated, useSpring } from "react-spring";

const useStyles = makeStyles((theme) => ({
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 1200,
    width: "100vw",
    height: "100vh",
    backgroundColor: "#000",
    opacity: ".5",
  },
  modalWrapper: {
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 1300,
    width: "100%",
    height: "100%",
    overflowX: "hidden",
    overflowY: "auto",
    outline: 0,
  },
  modal: {
    zIndex: 1301,
    position: "relative",
    margin: "3rem auto",
    maxWidth: "500px",
    background: "white",
  },
  modalHeader: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    fontSize: ".9rem",
    fontWeight: 700,
    border: "none",
    borderRadius: "3px",
    padding: ".3rem 1rem",
    marginLeft: ".5rem",
  },
  buttonDefault: {
    background: "#247BA0",
    color: "#fff",
  },
}));

export const Modal = (props: any) => {
  const { isShowing, hide } = props;
  const classes = useStyles();
  const modalRef = useRef(null);

  const slideDown = useSpring({
    opacity: isShowing ? 1 : 0,
    transform: isShowing ? "translate3d(0, 0%, 0)" : "translate3d(0, -100%, 0)",
  });

  const clickListener = useCallback(
    (e: MouseEvent) => {
      if (
        isShowing &&
        modalRef &&
        modalRef.current &&
        !(modalRef.current! as any).contains(e.target)
      ) {
        hide();
      }
    },
    [modalRef, hide, isShowing]
  );

  useEffect(() => {
    document.addEventListener("click", clickListener);
    return () => {
      document.removeEventListener("click", clickListener);
    };
  }, [clickListener]);

  return isShowing
    ? ReactDOM.createPortal(
        <>
          <div className={classes.overlay} />
          <div
            className={classes.modalWrapper}
            aria-modal
            aria-hidden
            tabIndex={-1}
            role="dialog"
          >
            <animated.div style={slideDown}>
              <div ref={modalRef} className={classes.modal}>
                {props.children}
              </div>
            </animated.div>
          </div>
        </>,
        document.body
      )
    : null;
};
